<template>
  <section>
    <template v-if="!loading && value">
      <UiContainer v-if="value.address" class="relative z-10">
        <UiButton
          severity="quaternary"
          class="!absolute left-4 top-3 mt-1 flex w-[calc(100%_-_32px)] items-center justify-between text-left md:top-5 md:w-fit lg:left-7"
          @click="copyAddress"
        >
          {{ value.address.normalized }}
          <UiIcon name="square-2-stack" class="ml-3 size-5 min-w-5 self-start text-icon-tertiary" />
        </UiButton>
      </UiContainer>
      <VenueYandexMap
        :value="[value]"
        :active-venue="null"
        :height="isMdAndUp ? '516px' : '320px'"
        disable-markers
      />
    </template>
    <template v-else>
      <VenueAddressInfoSkeleton />
    </template>
  </section>
</template>

<script setup lang="ts">
import copyToClipboard from 'copy-to-clipboard'
import { toast } from 'vue3-toastify'
import { type Venue, VenueYandexMap } from '@/5_entities/Venue'
import { useBreakpoint } from '@/6_shared/lib'
import { UiButton, UiContainer, UiIcon } from '@/6_shared/ui'
import VenueAddressInfoSkeleton from './VenueAddressInfoSkeleton.vue'

type PropType = {
  value?: Venue
  loading?: boolean
}

const props = withDefaults(defineProps<PropType>(), {
  value: undefined,
  loading: false
})
const {
  md: [isMdAndUp]
} = useBreakpoint()

const copyAddress = () => {
  if (!props.value?.address) return

  copyToClipboard(props.value.address.normalized)
  toast.success('Адрес скопирован')
}
</script>

<template>
  <UiContainer tag="section" class="grid grid-cols-5">
    <div class="col-span-5 lg:col-span-4">
      <template v-if="!loading && value">
        <UiTitle v-if="value.description" severity="h5">{{ value.title }}</UiTitle>
        <UiDescriptionAccordion v-if="value.description" :max-height="isLgAndUp ? 448 : 146">
          <div class="page-content text-sm" v-html="value.description" />
          <template #gradient>
            <div
              :class="[
                'absolute left-0 top-0  size-full',
                'to-100 from-78% bg-gradient-to-b from-gradient-sixfold-first to-gradient-sixfold-second'
              ]"
            />
          </template>
        </UiDescriptionAccordion>
      </template>
      <template v-else>
        <VenueDescriptionSkeleton class="w-full" />
      </template>
    </div>
  </UiContainer>
</template>

<script lang="ts" setup>
import type { Venue } from '@/5_entities/Venue'
import { useBreakpoint } from '@/6_shared/lib'
import { UiContainer, UiDescriptionAccordion, UiTitle } from '@/6_shared/ui'
import VenueDescriptionSkeleton from './VenueDescriptionSkeleton.vue'

type PropType = {
  value?: Venue
  loading?: boolean
}

withDefaults(defineProps<PropType>(), {
  loading: false,
  value: undefined
})
const {
  lg: [isLgAndUp]
} = useBreakpoint()
</script>

<template>
  <section>
    <template v-if="!loading && value">
      <FilterVenueDate
        :model-value="activeDate"
        :dates="value.availableDates"
        class="mb-4 md:mb-7"
        @update:model-value="emit('update:activeDate', $event)"
      />
      <template v-if="!repertoireLoadError">
        <template v-if="eventsByDay?.size">
          <div
            v-for="([day, seancePacks], index) in eventsForSelectedDays"
            :key="day.toString()"
            class="relative py-5 md:py-0"
            :class="[eventsByDay.size > 1 ? 'mb-6 pt-2 md:mb-16' : 'pt-5']"
          >
            <UiLoader v-if="loadingRepertoire" class="absolute z-10 inset-center" size="18" />
            <UiGradientBackground severity="primary" class="md:hidden" />
            <UiContainer v-if="Array.isArray(activeDate)" tag="header" class="mb-2">
              <UiDate
                :value="day"
                :template="isSameYear(day, new Date()) ? 'dd MMMM' : 'dd MMMM yyyy'"
                class="text-base font-semibold"
              />
            </UiContainer>
            <UiContainer
              tag="ul"
              class="relative flex flex-col gap-4 md:gap-12"
              :class="{ 'opacity-20': loadingRepertoire }"
            >
              <li v-for="(pack, packIndex) in seancePacks" :key="packIndex" class="flex flex-col">
                <LookVenueRepertoire
                  :value="pack"
                  @show-timetable="emit('showTimetable', $event, String(day))"
                />
                <template v-if="!allRepertoireLoaded && index + 1 === eventsByDay.size">
                  <template v-if="!repertoireLoadPageError">
                    <UiButton
                      v-if="repertoirePageLoaded"
                      type="button"
                      severity="secondary"
                      :disabled="loadingRepertoire"
                      class="mx-auto mt-4 w-full py-3 md:w-fit md:px-14"
                      @click="emit('loadMore')"
                    >
                      показать ещё
                    </UiButton>
                    <UiLoader v-else size="18" />
                  </template>
                  <div v-else class="mt-4 flex w-full justify-center font-semibold">
                    ой, не смогли загрузить,&nbsp;<UiButton text @click="emit('loadMore')">
                      попробовать еще раз
                    </UiButton>
                  </div>
                </template>
              </li>
            </UiContainer>
          </div>
        </template>
      </template>
      <div v-else class="text-center">
        <p class="text-sm font-medium">мы не смогли загрузить мероприятия за указанный период,</p>
        <p class="font-semibold">
          пожалуйста, выберите другие даты или попробуйте
          <UiButton text @click="emit('reloadDate')"> повторить запрос снова </UiButton>
        </p>
      </div>
    </template>
    <template v-else>
      <VenueRepertoireSkeleton />
    </template>
  </section>
</template>

<script setup lang="ts">
import { isSameYear, isSameDay, eachDayOfInterval } from 'date-fns'
import cloneDeep from 'lodash/cloneDeep.js'
import { computed, defineAsyncComponent } from 'vue'
import { type Event } from '@/5_entities/Event'
import type { Venue } from '@/5_entities/Venue'
import { formatDateForFilter } from '@/6_shared/lib'
import { UiButton, UiContainer, UiDate, UiGradientBackground, UiLoader } from '@/6_shared/ui'
import VenueRepertoireSkeleton from './VenueRepertoireSkeleton.vue'

type PropType = {
  value?: Venue
  loading?: boolean
  loadingRepertoire?: boolean
  activeDate?: Date | Date[]
  allRepertoireLoaded?: boolean
  repertoirePageLoaded?: boolean
  repertoireLoadError?: boolean
  repertoireLoadPageError?: boolean
}
type EmitType = {
  (e: 'showTimetable', id: Event['id'], date: string): void
  (e: 'update:activeDate', date: Date | Date[]): void
  (e: 'reloadDate'): void
  (e: 'loadMore'): void
}

const props = withDefaults(defineProps<PropType>(), {
  value: undefined,
  loading: false,
  activeDate: undefined,
  loadingRepertoire: false,
  allRepertoireLoaded: true,
  repertoirePageLoaded: true
})
const emit = defineEmits<EmitType>()

const LookVenueRepertoire = defineAsyncComponent({
  loader: async () => {
    const { LookVenueRepertoire } = await import('@/4_features/Venue')

    return LookVenueRepertoire
  }
})
const FilterVenueDate = defineAsyncComponent({
  loader: async () => {
    const { FilterVenueDate } = await import('@/4_features/Venue')

    return FilterVenueDate
  }
})

const MAX_SEANCES_IN_PACK = 10
const eventsByDay = computed((): Map<string, Venue['repertoire'][]> | undefined => {
  if (!props.value) return

  return props.value.repertoire.reduce((acc, item) => {
    const availableDates = item.seances.reduce((acc, seance) => {
      const key = formatDateForFilter(seance.beginsAt)
      if (!acc.includes(key)) acc.push(key)

      return acc
    }, [] as string[])

    availableDates.forEach((date) => {
      const availableSeances = item.seances.filter((seance) => isSameDay(seance.beginsAt, date))
      const eventInDate = cloneDeep(item)
      eventInDate.seances = availableSeances

      const key = formatDateForFilter(date)

      if (acc.has(key)) {
        const eventPacks = acc.get(key)
        const lastPack = eventPacks[eventPacks.length - 1]
        if (lastPack.length < MAX_SEANCES_IN_PACK) lastPack.push(eventInDate)
        else eventPacks.push([eventInDate])
      } else {
        acc.set(key, [[eventInDate]])
      }
    })

    return acc
  }, new Map())
})

const eventsForSelectedDays = computed((): Map<string, Venue['repertoire'][]> | undefined => {
  if (!props.activeDate) return eventsByDay.value
  const mapWithActiveDates = new Map()
  const addDaysInMap = (day: string) => {
    if (!eventsByDay.value) return

    const value = eventsByDay.value.get(day)

    mapWithActiveDates.set(day, value)
  }

  if (Array.isArray(props.activeDate)) {
    const datesInterval = eachDayOfInterval({
      start: props.activeDate[0],
      end: props.activeDate[1]
    })

    datesInterval.forEach((day) => {
      const date = formatDateForFilter(day)
      addDaysInMap(date)
    })
  } else {
    const date = formatDateForFilter(props.activeDate)
    addDaysInMap(date)
  }

  return mapWithActiveDates
})
</script>
